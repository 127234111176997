import { render, staticRenderFns } from "./Channel.vue?vue&type=template&id=e8abd3ca&scoped=true&"
import script from "./Channel.vue?vue&type=script&lang=js&"
export * from "./Channel.vue?vue&type=script&lang=js&"
import style0 from "./Channel.vue?vue&type=style&index=0&id=e8abd3ca&prod&lang=css&"
import style1 from "./Channel.vue?vue&type=style&index=1&id=e8abd3ca&prod&scoped=true&lang=css&"
import style2 from "./Channel.vue?vue&type=style&index=2&id=e8abd3ca&prod&lang=css&"
import style3 from "./Channel.vue?vue&type=style&index=3&id=e8abd3ca&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e8abd3ca",
  null
  
)

/* custom blocks */
import block0 from "./Channel.vue?vue&type=custom&index=0&blockType=body"
if (typeof block0 === 'function') block0(component)

export default component.exports